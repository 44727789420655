"use client";
import type { Video } from "@/cms/schema";
import { forwardRef, useEffect, useRef, useState } from "react";

interface Props {
  video_landscape: Video;
  video_portrait: Video;
}

export default function BackgroundVideo({
  video_landscape,
  video_portrait,
}: Props) {
  const portraitRef = useRef<HTMLVideoElement>(null);
  const landscapeRef = useRef<HTMLVideoElement>(null);
  const mediaQuery = window.matchMedia("(orientation: portrait)");

  const [isPortrait, setIsPortrait] = useState(mediaQuery.matches);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [portraitTime, setPortraitTime] = useState(0);
  const [landscapeTime, setLandscapeTime] = useState(0);

  useEffect(() => {
    if (isTransitioning) {
      const portrait = mediaQuery.matches;

      function videos() {
        var vs = [landscapeRef.current, portraitRef.current];
        if (!portrait) vs.reverse();
        return vs.map((v) => v!);
      }

      const [currentVideo, nextVideo] = videos();

      const onReadyToPlay = () => {
        nextVideo.removeEventListener("canplay", onReadyToPlay);
        setIsTransitioning(false);
      };

      currentVideo.removeEventListener("canplay", onReadyToPlay);

      nextVideo.addEventListener("canplay", onReadyToPlay);
      nextVideo.load();

      setIsPortrait(portrait);
    }

    const onOrientationChange = () => {
      const portrait = mediaQuery.matches;
      if (portrait) {
        setPortraitTime(landscapeRef.current!.currentTime);
      } else {
        setLandscapeTime(portraitRef.current!.currentTime);
      }

      return setIsTransitioning(true);
    };

    mediaQuery.addEventListener("change", onOrientationChange);
    return () => mediaQuery.removeEventListener("change", onOrientationChange);
  }, [isTransitioning, mediaQuery]);

  return (
    <div>
      <GradientOverlay />
      {(isPortrait || isTransitioning) && (
        <AutoPlayVideo
          ref={portraitRef}
          src={video_portrait}
          timestamp={portraitTime}
        />
      )}
      {(!isPortrait || isTransitioning) && (
        <AutoPlayVideo
          ref={landscapeRef}
          src={video_landscape}
          timestamp={landscapeTime}
        />
      )}
    </div>
  );
}

const GradientOverlay = () => (
  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black" />
);

const AutoPlayVideo = forwardRef<
  HTMLVideoElement,
  { src: Video; timestamp: number }
>(function AutoPlayVideo({ src, timestamp }, ref) {
  return (
    <video
      ref={ref}
      autoPlay
      playsInline
      loop
      muted
      className="absolute inset-0 -z-20 h-full w-full object-cover"
      src={`https://cms.cdtm.com/assets/${src.id}#t=${timestamp}`}
    />
  );
});
