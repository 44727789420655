"use client";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import tailwind from "../_utils/tailwind";

const Masonry = dynamic(() => import("react-layout-masonry"), { ssr: false });

export default function ExpandableMasonry({
  className,
  children,
}: HTMLAttributes<HTMLElement>) {
  const componentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [buttonState, setButtonState] = useState({
    visible: true,
    animate: false,
  });

  function toggle() {
    if (expanded) {
      /* Reset the scroll position to the top of the testimonial section after it's
       * closed. Without this, closing the expanded testimonials would leave the
       * user's scroll position too far down the shortened (not expanded) page,
       * potentially skipping sections below due to the change in webpage length. */
      // componentRef.current?.scrollIntoView();
      document.getElementById("testimonialSection")?.scrollIntoView();
    } else {
      /* Hide button without animation when expanding */
      setButtonState({ visible: false, animate: false });
    }

    setExpanded(!expanded);
  }

  function handleScroll() {
    const scrollY = window.scrollY;
    const componentRect = componentRef.current!.getBoundingClientRect();

    /* 'componentRect.top' changes with scroll position. By adding 'scrollY', we
     * get the constant Y coordinate for the section relative to the document */
    const sectionY = componentRect.y + scrollY;
    const showAtY = sectionY + 300;

    setButtonState({ visible: scrollY >= showAtY, animate: true });
  }

  useEffect(() => {
    if (expanded) {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [expanded]);

  return (
    <div
      ref={componentRef}
      className={clsx("relative", !expanded && "h-[700px] overflow-hidden")}
    >
      <Masonry columns={masonryBreakpoints()} gap={16} className={className}>
        {children}
      </Masonry>
      <div
        className={clsx(
          expanded ? "sticky -mt-32" : "absolute",
          buttonState.visible ? "opacity-100" : "opacity-0",
          buttonState.animate && "transition-opacity duration-300",
          "pointer-events-none inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-black pb-10 pt-32",
        )}
      >
        <button
          onClick={toggle}
          className={clsx(
            "pointer-events-auto relative rounded-lg border-b-2 border-t-2 border-gray-600 bg-gray-700 px-6 py-2 font-semibold",
            "transition-all duration-300 hover:border-gray-500 hover:bg-gray-600",
            !buttonState.visible && buttonState.animate && "translate-y-8",
          )}
        >
          {expanded ? "Got it, sounds like a cool place" : "Show more..."}
        </button>
      </div>
    </div>
  );
}

function masonryBreakpoints() {
  const columns = {
    sm: 1,
    md: 2,
    xl: 3,
    "3xl": 4,
  };

  var breakpoints: Record<number, number> = {};
  for (const [key, value] of Object.entries(columns) as [
    keyof typeof tailwind.theme.screens,
    number,
  ][]) {
    const breakpoint = parseInt(tailwind.theme.screens[key]);
    breakpoints[breakpoint] = value;
  }
  return breakpoints;
}
