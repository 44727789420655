import type { Config } from "tailwindcss";
import colors from "tailwindcss/colors";

export default {
  content: [
    "./pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./components/**/*.{js,ts,jsx,tsx,mdx}",
    "./app/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    fontSize: {
      xs: ["0.75rem", "1rem"],
      sm: ["0.875rem", "1.25rem"],
      base: ["1rem", "1.30rem"],
      lg: ["1.125rem", "1.35rem"],
      xl: ["1.25rem", "1.75rem"],
      "2xl": ["1.5rem", "2rem"],
      "3xl": ["1.95rem", "2.25rem"],
      "4xl": ["2.25rem", "2.5rem"],
      "5xl": ["3rem", "1"],
      "6xl": ["3.75rem", "1"],
      "7xl": ["4.5rem", "1"],
      "8xl": ["6rem", "1"],
      "9xl": ["8rem", "1"],
    },
    colors: {
      transparent: "transparent",
      current: "currentColor",
      inherit: "inherit",
      black: colors.black,
      white: colors.white,
      pink: colors.pink,
      violet: colors.violet,
      gray: colors.zinc /* more pleasant gray than a real gray */,
      /* color variants generated using https://uicolors.app */
      green: {
        "50": "#f9fce9",
        "100": "#eff7d0",
        "200": "#e0efa7",
        "300": "#cae373",
        DEFAULT: "#b0d243" /* CDTM green brand color */,
        "400": "#b0d243" /* same as default */,
        "500": "#94b929",
        "600": "#73931d",
        "700": "#57701b",
        "800": "#47591b",
        "900": "#3c4c1b",
        "950": "#1f2a09",
      },
      /* color variants generated using https://tints.dev and manually adjusted */
      blue: {
        50: "#FAFCFF",
        100: "#F1F6FE",
        200: "#E4EDF0",
        300: "#B8D4F9",
        400: "#92BEF6",
        500: "#68A4F3",
        600: "#2B7FEE",
        DEFAULT: "#0D4DA1" /* CDTM blue brand color */,
        700: "#0D4DA1",
        800: "#0A3D7F",
        900: "#082F63",
        950: "#041934"
      },
      recruitingOrange: {
        "50": "#fff7eb",
        "100": "#fde8c8",
        "200": "#fbd18c",
        "300": "#f9b350",
        DEFAULT: "#f89d33" /* CDTM orange recruiting color */,
        "400": "#f89d33" /* same as default */,
        "500": "#f1740f",
        "600": "#d65209",
        "700": "#b1350c",
        "800": "#902a10",
        "900": "#762311",
        "950": "#440e04",
      },
      recruitingRose: {
        "50": "#faf5fa",
        "100": "#f7ecf6",
        "200": "#f0daef",
        "300": "#e5bce2",
        "400": "#d393ce",
        "500": "#c371bb",
        DEFAULT: "#b35fa6" /* CDTM rose recruiting color */,
        "600": "#b35fa6" /* same as default */,
        "700": "#944285",
        "800": "#7b396e",
        "900": "#68335d",
        "950": "#3e1937",
      },
    },
    extend: {
      fontFamily: {
        sans: ["var(--font-avenir)"],
      },
      screens: {
        "3xl": "2200px",
      },
    },
  },
  plugins: [],
} satisfies Config
