"use client";
import clsx from "clsx";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

interface Props {
  className?: string;
}

export default function Reveal({
  children,
  className,
}: PropsWithChildren<Props>) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className={clsx("relative", className)}
    >
      {children}
    </motion.div>
  );
}
